import { useEffect, useState } from "react";
import { supabase } from "./App";
import JokeInput from "./JokeInput";
//import './index.css';

function DailyJokes() {

    const today = new Date();
    const date = today.toLocaleDateString();
    const [jokes, setJokes] = useState<any>([]);
    const [db_jokes, setDbJokes] = useState<any>([]);
    const [savedText, setSavedText] = useState<any>('unsaved');
    const [publishEnabled, setPublishEnabled] = useState<any>('disabled');
    const [publishedText, setPublishedText] = useState<any>('unpublished');
    const [publishChecked, setPublishedChecked] = useState<any>('')
    const [showButtons, setShowButtons] = useState<any>(true)

    useEffect(() => {
        GetJokes();
    }, [savedText]);

    function HandleQuickfillPaste(e: any) {
        const text = e.clipboardData.getData('text');
        const lines = text.split(/\r\n|\r|\n/).filter((line: String) => line !== '')
        setJokes([...lines])
        if (lines.length >= 5) {
            setPublishEnabled('')
        }
    }

    async function GetJokes() {
        const { data, error } = await supabase
            .from('jokes')
            .select()
            .order('number', { ascending: true })

        if (error) {
            console.warn(error);
        } else if (data && data[0] !== undefined) {

            setDbJokes(data);
            setJokes(data.map((joke) => {
                return joke.text
            }))
            console.log(jokes);

            if (jokes.length >= 5) {
                setPublishEnabled('')
            }

            setSavedText('saved')

            if (data[0].is_published) {
                setPublishedText('published')
                setPublishEnabled('disabled')
                setPublishedChecked('checked')
                setShowButtons(false)
            }
        }
    }

    async function UpdateJokes(e: any) {
        e.preventDefault();

        const formDataObj = new FormData(e.target);
        const formData = Object.values(Object.fromEntries(formDataObj));

        const isPublished = formData[formData.length - 1] === "on" ? true : false;

        if (isPublished) {
            formData.pop()
        }
        const payload = formData.map((joke, i) => {
            return {
                date_published_for: today.toISOString(),
                is_daily: true,
                is_published: isPublished,
                number: i + 1,
                text: joke,
                votes: 0
            }
        })

        const { error } = await supabase
            .from('jokes')
            .upsert(payload)
        if (error) {
            console.warn(error);
        } else {
            window.location.reload();
        }
    }

    async function DeleteJokes() {
        const { error } = await supabase
            .from('jokes')
            .delete()
            .eq('date_published_for', today.toISOString())
        if (error) {
            console.warn(error)
        } else {
            window.location.reload();
        }
    }

    function HandleReset() {
        DeleteJokes();
    }

    return (
        <>
            <div className="flex">
                <div className="w-full p-2">
                    <form onSubmit={UpdateJokes}>
                        <h1 className="text-xl ml-8 mt-4 font-bold">Enter jokes for {date} (<span className={savedText === 'saved' ? "text-lg" : "text-tomato"}>{savedText}</span>, <span className={publishedText === 'published' ? "text-lg" : "text-tomato"}>{publishedText}</span>)</h1>
                        <JokeInput name='0' index={0} jokes={jokes} db_jokes={db_jokes} setJokes={setJokes} disable={publishChecked === 'checked'} />
                        <JokeInput name='1' index={1} jokes={jokes} db_jokes={db_jokes} setJokes={setJokes} disable={publishChecked === 'checked'} />
                        <JokeInput name='2' index={2} jokes={jokes} db_jokes={db_jokes} setJokes={setJokes} disable={publishChecked === 'checked'} />
                        <JokeInput name='3' index={3} jokes={jokes} db_jokes={db_jokes} setJokes={setJokes} disable={publishChecked === 'checked'} />
                        <JokeInput name='4' index={4} jokes={jokes} db_jokes={db_jokes} setJokes={setJokes} disable={publishChecked === 'checked'} />
                        <div className={showButtons ? '' : 'hidden'}>
                            <div><input
                                name="publish"
                                className="ml-10 mt-4"
                                type="checkbox"
                                checked={publishChecked}
                                disabled={publishEnabled}
                                onClick={() => publishChecked === '' ? setPublishedChecked('checked') : setPublishedChecked('')}
                            ></input><span className="ml-2">Publish (complete all jokes to publish)</span></div>
                            <button className='bg-b w-20 ml-10 mt-4 p-2 rounded-md hover:bg-lb ' type="submit">Save</button>
                            <button onClick={() => HandleReset()} className='bg-lb w-20 ml-10 mt-4 p-2 rounded-md hover:bg-tomato '>Reset</button>
                        </div>
                    </form>
                </div>
                <div className={(showButtons ? '' : 'hidden ') + 'w-1/4 mr-20'}>
                    <div className="h-40"></div>
                    <div className="p-2 ml-8 font-bold">Quickfill: Copy + Paste rows from scratchpad here</div>
                    <textarea
                        onPaste={(e) => HandleQuickfillPaste(e)}
                        className='text-black h-60 mt-20 ml-8 block p-2 rounded-md border-0 align-top'></textarea>
                </div>
            </div>
        </>
    )
}

export default DailyJokes;