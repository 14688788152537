import { useState } from "react";
import './JokeInput.css';

interface JokeInputProps {
    name: string,
    index: number,
    jokes: string[],
    db_jokes: [],
    setJokes: Function,
    disable: boolean
}

function JokeInput(props: JokeInputProps) {

    return (<>
        <div className="flex mt-4">
            <div className="text-l ml-6 mt-2 font-bold">{props.index + 1}</div>
            <textarea name={props.name} 
                rows={4}
                value={props.jokes[props.index] ? props.jokes[props.index] : props.db_jokes[props.index] ? props.db_jokes[props.index] : ''}
                onChange={(e) => {
                    let payload = [...props.jokes];
                    payload[props.index] = e.target.value;
                    props.setJokes(payload)
                }}
                className={(props.disable ? 'disable ': '') + ' text-black text-xs w-full block ml-12 p-2 rounded-md border-0 align-top'}></textarea>
        </div>
    </>)
}

export default JokeInput