import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DailyJokes from './DailyJokes';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div className='flex'>
            <div className='ml-8 grid gap-1 grid-cols-3 grid-rows-3'>
                <Link className='bg-lg rounded-md m-2 p-2 hover:bg-lb' to="/dailyjokes">Write Jokes Now!</Link>
                <Link className='bg-tomato rounded-md m-2 p-2 hover:bg-khaki' to="/jokehistory">Your Joke History</Link>
                <div></div>
                <div>Newest</div>
                <div>Top of the Day</div>
                <div>Top of the Week</div>
                <div>Top of the Month</div>                
                <div>Best Streak</div>
                <div>Spotlight</div>
            </div>
        </div>
    )
}

export default Home