import { supabase } from "./App";
import { useEffect, useState } from "react";
import './JokeHistory.css'

function JokeHistory() {

    const [loading, setLoading] = useState<boolean>(true);
    const [jokes, setJokes] = useState<any>([]);


    useEffect(() => {
        GetJokes();
    }, [loading])

    async function GetJokes() {
        const { data, error } = await supabase
            .from('jokes')
            .select()
            .order('number', { ascending: true })

        if (error) {
            console.warn(error);
        } else if (data && data[0] !== undefined) {
            const jokesTable = data.map((joke) => {
                return <tr>
                    <td className="p-2 text-xs">{joke.date_published_for}</td>
                    <td className="p-2 text-xs">{joke.is_published ? 'Yes' : 'No'}</td>
                    <td className="p-2 text-xs">{joke.text}</td>
                </tr>
            })
            setJokes(jokesTable)
            setLoading(false);
        }
    }
    return (<>
        <table className="table-fixed">
            <thead>
                <tr>
                    <th className="dateCol">Date</th>
                    <th>Published</th>
                    <th>Joke</th>
                </tr>
            </thead>
            <tbody>{jokes}</tbody>
        </table>
    </>)
}

export default JokeHistory;