import { useState, useEffect } from 'react';
import './App.css';
import './output.css'
import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import DailyJokes from './DailyJokes';
import JokeHistory from './JokeHistory';
import Profile from './Profile';
import Tips from './Tips';
import About from './About';
import Search from './Search';
import logo from './jokereps2.png'
import profile_pic from './profile_pic.png'
export const supabase = createClient('https://vbqffmmireyjmsfblnvq.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZicWZmbW1pcmV5am1zZmJsbnZxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDc5ODA0ODgsImV4cCI6MjAyMzU1NjQ4OH0.G_A4boAue_eVycK1mk5Wff58OByzGOBM8h_TcYFPkuo')

function App() {
  const [session, setSession] = useState<any | null>(null)

  const today = new Date();
  const date = today.toLocaleDateString();
  const [userName, setUserName] = useState<any>('Profile');

  const navigate = useNavigate();
  
  async function CheckUsername() {
    const { data, error } = await supabase
      .from('user_details')
      .select()
    
    if(error){
      console.warn(error)
    } else {
      console.log(data)
      if(!data || !data[0].user_name){
        navigate("/profile");
      } else {
        setUserName(data[0].user_name);
      }
    }
  }

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })


    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
    return () => subscription.unsubscribe()
  }, [])

  if (!session) {
    return (<>
      <div className='flex justify-center'>
        <div className='w-1/4'>
          <Auth supabaseClient={supabase} 
          appearance={{ theme: ThemeSupa }} 
          providers={[]} />
        </div>
      </div>
    </>)
  }
  else {

    CheckUsername();

    return (<>
      <nav>
        <div className='flex p-2 mr-8'>
          <Link to="/"><img src={logo} width={100} height={100}></img></Link>
          <div className='w-full ml-8 mr-8'>
            <div className='grid grid-col-2 tm-8 p-4'>
              {/* <div className='text-2xl p-2' ><Link to="/">JOKEreps</Link></div> */}
              <div className='text-sm p-3 ostrich'>"swole-ass joke brain..."</div>
            </div>
            <ul className='flex align-middle bg-lb rounded-md mr-5 p-4'>
              <li className='p-2 hover:bg-khaki'><Link to="/"><span className="ostrich text-xl">Home</span></Link></li>
              <li className='p-2 hover:bg-khaki'><Link to="/dailyjokes"><span className="ostrich text-xl">My Daily Jokes</span></Link></li>
              <li className='p-2 hover:bg-khaki'><Link to="/jokehistory"><span className="ostrich text-xl">My Joke History</span></Link></li>
              <li className='p-2 hover:bg-khaki'><Link to="/search"><span className="ostrich text-xl">Search</span></Link></li>
              <li className='p-2 hover:bg-khaki'><Link to="/tips"><span className="ostrich text-xl">Joke Writing Tips</span></Link></li>
              <li className='p-2 hover:bg-khaki'><Link to="/about"><span className="ostrich text-xl">About</span></Link></li>
            </ul>
          </div>

          <div className='bold p-3' ><Link to="/Profile"><img src={profile_pic} width={60}></img><span className="ostrich text-xl">{userName}</span></Link></div>
        </div>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dailyjokes" element={<DailyJokes />} />
        <Route path="/jokehistory" element={<JokeHistory />} />
        <Route path="/search" element={<Search />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/tips" element={<Tips />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </>)
  }
}

export default App;
