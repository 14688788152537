import { useEffect, useInsertionEffect, useState } from "react";
import { supabase } from "./App";
import './Profile.css'

function Profile() {

    const [loading, setLoading] = useState<boolean>(true);
    const [userName, setUserName] = useState<string>('');
    const [catchPhrase, setCatchPhrase] = useState<string>('');
    const [bio, setBio] = useState<string>('');

    async function getUserDetails() {
        const { data, error } = await supabase
            .from('user_details')
            .select()

        if (error) {
            console.warn(error)
        } else {
            if(data){                
                setUserName(data[0].user_name)
                setCatchPhrase(data[0].catch_phrase)
                setBio(data[0].bio)
            }
        }
        setLoading(false)
    }

    async function saveUserDetails(e: any) {
        e.preventDefault();
        const formDataObj = new FormData(e.target);
        const formData = Object.fromEntries(formDataObj);
        const { error } = await supabase
            .from('user_details')
            .upsert({
                user_name: formData.username,
                catch_phrase: formData.catchPhrase,
                bio: formData.bio
            })
        if (error) {
            console.warn(error);
        } else {
            window.location.reload();
        }
    }
    useEffect(() => {
        getUserDetails();
    }, [loading])

    return (<>
        <div>
            <form onSubmit={saveUserDetails}>
                <h1 className="text-xl ml-8 mt-4 font-bold">Username</h1>
                {userName == undefined ? <div className="ml-8 text-tomato">Please enter a user name</div> : ''}
                <input className="text-black mt-20 ml-8 block p-2 rounded-md border-0 align-top"
                    name="username"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}></input>
                <h1 className="text-xl ml-8 mt-4 font-bold">Catch Phrase</h1>
                <input className="catchPhrase text-black mt-20 ml-8 w-16 block p-2 rounded-md border-0 align-top"
                    name="catchPhrase"
                    value={catchPhrase}
                    onChange={(e) => setCatchPhrase(e.target.value)}></input>
                <div className="mr-12">
                    <h1 className="text-xl ml-8 mt-4 font-bold">Bio</h1>
                    <textarea name="bio"
                        className="bio text-black text-xs block ml-8 p-2 rounded-md border-0 align-top"
                        rows={5}
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                    ></textarea>
                </div>
                <button className='bg-b w-20 ml-8 mt-5 p-2 rounded-md hover:bg-lb ' type="submit">Save</button>
            </form>
        </div>
    </>)
}

export default Profile;